<template>
  <div class="app-container common-list-page">
    <el-form
      :model="resetForm"
      :rules="resetFormRules"
      ref="resetForm"
      status-icon
      label-width="100px"
    >
      <el-form-item label="旧密码：" prop="oldPassword">
        <el-input type="password" v-model="resetForm.oldPassword" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newPassword">
        <el-input type="password" v-model="resetForm.newPassword" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="confirm">
        <el-input type="password" v-model="resetForm.confirm" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click.native.prevent="toAmend">确认修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
    import axios from 'axios'
  export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (!value) {
          callback(new Error("请输入新密码"));
        } else if (value == this.resetForm.oldPassword) {
            callback(new Error("新密码不能与旧密码一致!"));
        } else if (value.toString().length < 7 || value.toString().length > 12) {
          callback(new Error("密码长度为6-18位"));
        } else {
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.resetForm.newPassword) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      };
      return {
        resetForm: {
          //传给后台所需要的参数
            oldPassword: "",
            newPassword: "",
            confirm: ""
        },
        resetFormRules: {
            oldPassword: [
                { required: true, message: "请输入旧密码", trigger: 'blur' },
                { min: 7, max: 12, message: '长度在 7 到 12 个字符', trigger: 'blur' },
                {
                    pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^(0-9a-zA-Z)]).{8,12}$',
                    message: "密码设置需要数字+字母+特殊符合",
                    trigger: "blur"
                }
            ],
            newPassword: [
              { required: true, validator: validatePass, trigger: 'blur' },
              {
                  pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^(0-9a-zA-Z)]).{8,12}$',
                  message: "密码设置需要数字+字母+特殊符合",
                  trigger: "blur"
              }
            ],
            confirm: [
              { required: true, validator: validatePass2, trigger: "blur" }
            ]
        }
      };
    },
    methods: {
      toAmend() {
          axios.post(`${this.$apiPath}/api/pm/user/updatePasswordByOld?oldPassword=`+ this.resetForm.oldPassword + '&newPassword=' + this.resetForm.newPassword + '&confirm=' + this.resetForm.confirm).then(res=>{
              if(res.data.success == true){
                  this.$alert('密码修改成功，请重新登录', '提示', {
                      confirmButtonText: '确定',
                  });
                  sessionStorage.removeItem('menu');
                  sessionStorage.removeItem('userButtons');
                  this.$cookies.set("token",'',-1, '/');
                  this.$router.replace('/login');
              }else{
                  this.$alert(res.data.msg, '提示', {
                      confirmButtonText: '确定',
                  });
              }
          })
      },

    },
  };
</script>

<style scoped>
  body{
    background: #ffffff;
  }
  .el-form {
    width: 60%;
    margin: 50px auto 0;
    text-align: center;
    button {
      margin: 20px 0 0;
    }
  }
</style>
